// @generated by protoc-gen-es v2.2.0
// @generated from file hmm/identity/identity.proto (package hmm.identity, syntax proto3)
/* eslint-disable */

import { enumDesc, fileDesc, messageDesc, serviceDesc, tsEnum } from "@bufbuild/protobuf/codegenv1";

/**
 * Describes the file hmm/identity/identity.proto.
 */
export const file_hmm_identity_identity = /*@__PURE__*/
  fileDesc("ChtobW0vaWRlbnRpdHkvaWRlbnRpdHkucHJvdG8SDGhtbS5pZGVudGl0eSK6AQoHQ29udGV4dBIRCgljaGFsbGVuZ2UYASABKAkSEQoJcmVjaXBpZW50GAIgASgJEhAKCHJlbWVtYmVyGAMgASgIEigKBHR5cGUYBCABKA4yGi5obW0uaWRlbnRpdHkuQ29udGV4dC5UeXBlIk0KBFR5cGUSEAoMVFlQRV9VTktOT1dOEAASDgoKVFlQRV9FTUFJTBABEg8KC1RZUEVfR09PR0xFEAISEgoOVFlQRV9NSUNST1NPRlQQAyKMAQoGQWN0aW9uEicKBHR5cGUYASABKA4yGS5obW0uaWRlbnRpdHkuQWN0aW9uLlR5cGUSDQoFdmFsdWUYAiABKAkiSgoEVHlwZRIQCgxUWVBFX1VOS05PV04QABINCglUWVBFX0lORk8QARIRCg1UWVBFX1JFRElSRUNUEAISDgoKVFlQRV9FUlJPUhADIj0KE0F0dGVtcHRMb2dpblJlcXVlc3QSJgoHY29udGV4dBgBIAEoCzIVLmhtbS5pZGVudGl0eS5Db250ZXh0IjwKFEF0dGVtcHRMb2dpblJlc3BvbnNlEiQKBmFjdGlvbhgBIAEoCzIULmhtbS5pZGVudGl0eS5BY3Rpb24iNgoMTG9naW5SZXF1ZXN0EiYKB2NvbnRleHQYASABKAsyFS5obW0uaWRlbnRpdHkuQ29udGV4dCI1Cg1Mb2dpblJlc3BvbnNlEiQKBmFjdGlvbhgCIAEoCzIULmhtbS5pZGVudGl0eS5BY3Rpb24iTQoUVmFsaWRhdGVUb2tlblJlcXVlc3QSJgoHY29udGV4dBgBIAEoCzIVLmhtbS5pZGVudGl0eS5Db250ZXh0Eg0KBXRva2VuGAIgASgJIj0KFVZhbGlkYXRlVG9rZW5SZXNwb25zZRIkCgZhY3Rpb24YASABKAsyFC5obW0uaWRlbnRpdHkuQWN0aW9uIjgKDkNvbnNlbnRSZXF1ZXN0EiYKB2NvbnRleHQYASABKAsyFS5obW0uaWRlbnRpdHkuQ29udGV4dCI3Cg9Db25zZW50UmVzcG9uc2USJAoGYWN0aW9uGAEgASgLMhQuaG1tLmlkZW50aXR5LkFjdGlvbiI3Cg1Mb2dvdXRSZXF1ZXN0EiYKB2NvbnRleHQYASABKAsyFS5obW0uaWRlbnRpdHkuQ29udGV4dCI2Cg5Mb2dvdXRSZXNwb25zZRIkCgZhY3Rpb24YASABKAsyFC5obW0uaWRlbnRpdHkuQWN0aW9uMpsDCg9Qcm92aWRlclNlcnZpY2USVwoMQXR0ZW1wdExvZ2luEiEuaG1tLmlkZW50aXR5LkF0dGVtcHRMb2dpblJlcXVlc3QaIi5obW0uaWRlbnRpdHkuQXR0ZW1wdExvZ2luUmVzcG9uc2UiABJCCgVMb2dpbhIaLmhtbS5pZGVudGl0eS5Mb2dpblJlcXVlc3QaGy5obW0uaWRlbnRpdHkuTG9naW5SZXNwb25zZSIAEloKDVZhbGlkYXRlVG9rZW4SIi5obW0uaWRlbnRpdHkuVmFsaWRhdGVUb2tlblJlcXVlc3QaIy5obW0uaWRlbnRpdHkuVmFsaWRhdGVUb2tlblJlc3BvbnNlIgASSAoHQ29uc2VudBIcLmhtbS5pZGVudGl0eS5Db25zZW50UmVxdWVzdBodLmhtbS5pZGVudGl0eS5Db25zZW50UmVzcG9uc2UiABJFCgZMb2dvdXQSGy5obW0uaWRlbnRpdHkuTG9nb3V0UmVxdWVzdBocLmhtbS5pZGVudGl0eS5Mb2dvdXRSZXNwb25zZSIAQjVaM2dpdGh1Yi5jb20vaHVtYW4tbWFkZS1tYWNoaW5lL2htbS9pZGVudGl0eTtpZGVudGl0eWIGcHJvdG8z");

/**
 * Describes the message hmm.identity.Context.
 * Use `create(ContextSchema)` to create a new message.
 */
export const ContextSchema = /*@__PURE__*/
  messageDesc(file_hmm_identity_identity, 0);

/**
 * Describes the enum hmm.identity.Context.Type.
 */
export const Context_TypeSchema = /*@__PURE__*/
  enumDesc(file_hmm_identity_identity, 0, 0);

/**
 * @generated from enum hmm.identity.Context.Type
 */
export const Context_Type = /*@__PURE__*/
  tsEnum(Context_TypeSchema);

/**
 * Describes the message hmm.identity.Action.
 * Use `create(ActionSchema)` to create a new message.
 */
export const ActionSchema = /*@__PURE__*/
  messageDesc(file_hmm_identity_identity, 1);

/**
 * Describes the enum hmm.identity.Action.Type.
 */
export const Action_TypeSchema = /*@__PURE__*/
  enumDesc(file_hmm_identity_identity, 1, 0);

/**
 * @generated from enum hmm.identity.Action.Type
 */
export const Action_Type = /*@__PURE__*/
  tsEnum(Action_TypeSchema);

/**
 * Describes the message hmm.identity.AttemptLoginRequest.
 * Use `create(AttemptLoginRequestSchema)` to create a new message.
 */
export const AttemptLoginRequestSchema = /*@__PURE__*/
  messageDesc(file_hmm_identity_identity, 2);

/**
 * Describes the message hmm.identity.AttemptLoginResponse.
 * Use `create(AttemptLoginResponseSchema)` to create a new message.
 */
export const AttemptLoginResponseSchema = /*@__PURE__*/
  messageDesc(file_hmm_identity_identity, 3);

/**
 * Describes the message hmm.identity.LoginRequest.
 * Use `create(LoginRequestSchema)` to create a new message.
 */
export const LoginRequestSchema = /*@__PURE__*/
  messageDesc(file_hmm_identity_identity, 4);

/**
 * Describes the message hmm.identity.LoginResponse.
 * Use `create(LoginResponseSchema)` to create a new message.
 */
export const LoginResponseSchema = /*@__PURE__*/
  messageDesc(file_hmm_identity_identity, 5);

/**
 * Describes the message hmm.identity.ValidateTokenRequest.
 * Use `create(ValidateTokenRequestSchema)` to create a new message.
 */
export const ValidateTokenRequestSchema = /*@__PURE__*/
  messageDesc(file_hmm_identity_identity, 6);

/**
 * Describes the message hmm.identity.ValidateTokenResponse.
 * Use `create(ValidateTokenResponseSchema)` to create a new message.
 */
export const ValidateTokenResponseSchema = /*@__PURE__*/
  messageDesc(file_hmm_identity_identity, 7);

/**
 * Describes the message hmm.identity.ConsentRequest.
 * Use `create(ConsentRequestSchema)` to create a new message.
 */
export const ConsentRequestSchema = /*@__PURE__*/
  messageDesc(file_hmm_identity_identity, 8);

/**
 * Describes the message hmm.identity.ConsentResponse.
 * Use `create(ConsentResponseSchema)` to create a new message.
 */
export const ConsentResponseSchema = /*@__PURE__*/
  messageDesc(file_hmm_identity_identity, 9);

/**
 * Describes the message hmm.identity.LogoutRequest.
 * Use `create(LogoutRequestSchema)` to create a new message.
 */
export const LogoutRequestSchema = /*@__PURE__*/
  messageDesc(file_hmm_identity_identity, 10);

/**
 * Describes the message hmm.identity.LogoutResponse.
 * Use `create(LogoutResponseSchema)` to create a new message.
 */
export const LogoutResponseSchema = /*@__PURE__*/
  messageDesc(file_hmm_identity_identity, 11);

/**
 * @generated from service hmm.identity.ProviderService
 */
export const ProviderService = /*@__PURE__*/
  serviceDesc(file_hmm_identity_identity, 0);

